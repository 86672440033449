import translate from "../../../../directives/translate";

import UiFloatMixin from "../UiFloatMixin";
import UIReadOnlyMixin from "../../UIReadOnlyMixin";

import AFiltersAPI from "aloha-vue/src/compositionAPI/AFiltersAPI";

// @vue/component
export default {
  name: "UiFloatReadOnly",
  directives: {
    translate,
  },
  mixins: [
    UiFloatMixin,
    UIReadOnlyMixin,
  ],
  setup() {
    const {
      filterCurrency,
    } = AFiltersAPI();

    return {
      filterCurrency,
    };
  },
  computed: {
    modelLocal() {
      const SUFFIX = this.suffixOption_UiFloatMixin;
      return `${ this.prefix_UiFloatMixin }${ this.filterCurrency(this.model, { suffix: SUFFIX, digits: this.digits_UiFloatMixin, digitGrouping: !this.no_separator_UiFloatMixin }) }`;
    },
  },
};
