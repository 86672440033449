import UiBelegeExtraFieldFinanzierung from "./ui/UiBelegeExtraFieldFinanzierung/UiBelegeExtraFieldFinanzierung.vue";
import UiBelegeExtraFieldKosten from "./ui/UiBelegeExtraFieldKosten/UiBelegeExtraFieldKosten.vue";
import UiBelegeField from "./ui/UiBelegeField/UiBelegeField.vue";
import UiBelegpruefartFelder from "./ui/UiBelegpruefartFelder/UiBelegpruefartFelder.vue";
import UiSignalKofiPosConfig from "./ui/UiSignalKofiPosConfig/UiSignalKofiPosConfig.vue";
import UiSignalKofiRegeldata from "./ui/UiSignalKofiRegeldata/UiSignalKofiRegeldata.vue";
import UiSignalPeriodenRegeldata from "./ui/UiSignalPeriodenRegeldata/UiSignalPeriodenRegeldata.vue";
import UiZusatzprozent from "./ui/UiZusatzprozent/UiZusatzprozent.vue";
import UiZuschussPeriodenConfig from "./ui/UiZuschussPeriodenConfig/UiZuschussPeriodenConfig.vue";

import UiBelegeFieldReadOnly from "./ui/UiBelegeField/UiBelegeFieldReadOnly/UiBelegeFieldReadOnly.vue";
import UiBelegpruefartFelderReadOnly from "./ui/UiBelegpruefartFelder/UiBelegpruefartFelderReadOnly/UiBelegpruefartFelderReadOnly.vue";
import UiPauschalePeriodenConfig from "./ui/UiPauschalePeriodenConfig/UiPauschalePeriodenConfig.vue";
import UiPauschalePeriodenConfigReadOnly from "./ui/UiPauschalePeriodenConfig/UiPauschalePeriodenConfigReadOnly/UiPauschalePeriodenConfigReadOnly.vue";
import UiSignalPeriodenRegeldataReadOnly from "./ui/UiSignalPeriodenRegeldata/UiSignalPeriodenRegeldataReadOnly/UiSignalPeriodenRegeldataReadOnly.vue";
import UiSignalKofiPosConfigReadOnly from "./ui/UiSignalKofiPosConfig/UiSignalKofiPosConfigReadOnly/UiSignalKofiPosConfigReadOnly.vue";
import UiSignalKofiRegeldataReadOnly from "./ui/UiSignalKofiRegeldata/UiSignalKofiRegeldataReadOnly/UiSignalKofiRegeldataReadOnly.vue";
import UiZusatzprozentReadOnly from "./ui/UiZusatzprozent/UiZusatzprozentReadOnly/UiZusatzprozentReadOnly.vue";
import UiZuschussPeriodenConfigReadOnly from "./ui/UiZuschussPeriodenConfig/UiZuschussPeriodenConfigReadOnly/UiZuschussPeriodenConfigReadOnly.vue";

export default {
  UiBelegeExtraFieldFinanzierung,
  UiBelegeExtraFieldKosten,
  UiBelegeField,
  UiBelegpruefartFelder,
  UiPauschalePeriodenConfig,
  UiSignalKofiPosConfig,
  UiSignalKofiRegeldata,
  UiSignalPeriodenRegeldata,
  UiZusatzprozent,
  UiZuschussPeriodenConfig,
};

export const READ_ONLY = {
  UiBelegeFieldReadOnly,
  UiBelegpruefartFelderReadOnly,
  UiPauschalePeriodenConfigReadOnly,
  UiSignalKofiPosConfigReadOnly,
  UiSignalKofiRegeldataReadOnly,
  UiSignalPeriodenRegeldataReadOnly,
  UiZusatzprozentReadOnly,
  UiZuschussPeriodenConfigReadOnly,
};

export const MAPPING = {
  belege_extra_field_kosten: "ui-belege-extra-field-kosten",
  belege_extra_field_finanz: "ui-belege-extra-field-finanzierung",
  belege_field: "ui-belege-field",
  belegpruefart_felder: "ui-belegpruefart-felder",
  pauschale_perioden_config: "ui-pauschale-perioden-config",
  perioden_zuschuss_config: "ui-zuschuss-perioden-config",
  signal_kofi_regeldata: "ui-signal-kofi-regeldata",
  signal_kofi_posconfig: "ui-signal-kofi-pos-config",
  signal_perioden_regeldata: "ui-signal-perioden-regeldata",
  zusatzprozent_field: "ui-zusatzprozent",
};

export const MAPPING_READONLY = {
  belege_extra_field_kosten: "ui-dynamische-form-definition-read-only",
  belege_extra_field_finanz: "ui-dynamische-form-definition-read-only",
  belege_field: "ui-belege-field-read-only",
  belegpruefart_felder: "ui-belegpruefart-felder-read-only",
  pauschale_perioden_config: "ui-pauschale-perioden-config-read-only",
  perioden_zuschuss_config: "ui-zuschuss-perioden-config-read-only",
  signal_kofi_regeldata: "ui-signal-kofi-regeldata-read-only",
  signal_kofi_posconfig: "ui-signal-kofi-pos-config-read-only",
  signal_perioden_regeldata: "ui-signal-perioden-regeldata-read-only",
  zusatzprozent_field: "ui-zusatzprozent-read-only",
};

export const MODEL = {
  // belege_extra_field_kosten: () => ({ __new__: true }),
  // belege_extra_field_finanz: () => ({ __new__: true }),
};

export const EXTRA_OPTIONS_FOR_TYPE = {
};
