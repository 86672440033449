import { toFormElementFromParameter } from "../../../../global/functions/mappingForParameterToFormElement";

export default {
  data() {
    return {
      jsonParam: {
        bez: "_LBL_GR_SIGNAL_KOFI_CONFIG_POS_CONFIG_",
        default: null,
        help_text: "_TXT_GR_SIGNAL_KOFI_CONFIG_POS_CONFIG_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: true,
          required: false,
        },
        typ: "validated_json",
        many: true,
        as_json: false,
        unique_fields: [
          "id",
          "regexp",
        ],
        key_field: null,
        pos_field: null,
        min_length: null,
        max_length: null,
        element_label: "Element",
        element_template: "{{id}}: {{regexp}}",
        element_errors: true,
        deactivate_ordering: false,
        deactivate_preview: false,
        hide_pos: false,
        fields: {
          id: {
            prio: 0,
            bez: "Technische ID",
            default: null,
            help_text: "Über diesen Schlüssel werden die Ermittelten Daten bereitgestellt",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              max_length: null,
              min_length: null,
            },
            typ: "string",
          },
          regexp: {
            prio: 1,
            bez: "Suchbegriff",
            default: null,
            help_text: "Über diesen Wert werden die gewünschten Daten aus der signalsendenden Regel gesucht. Entspricht meistens einer technischen ID in der Regel.",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              max_length: null,
              min_length: null,
            },
            typ: "string",
          },
          katalog: {
            prio: 2,
            bez: "Katalog",
            default: null,
            help_text: "Wenn angegeben, wird der für die Positions-Identifikation ermittelte Wert versucht über den Katalog aufzulösen. Bei Erfolg wird die ermittelte Bezeichnung für die Variable in der Bezeichnungsvorlage der Position verwendet",
            readonly: false,
            options: {
              allow_null: true,
              required: false,
              displaymode: "select2",
            },
            typ: "single_choice",
            katalog: "katalog_mit_dynkatalog",
          },
        },
        prio: 5,
        wert: null,
      },
    };
  },
  computed: {
    jsonOptions() {
      return toFormElementFromParameter({
        obj: { dimensionen: this.jsonParam },
        showLabel: true,
        staticOptions: {
          required: this.options.required,
          options: this.options.options,
        },
      }).list[0];
    },
  },
};
