import PuxIcon from "../../PuxIcon/PuxIcon.vue";
import PuxTooltip from "../../PuxTooltip/PuxTooltip.vue";
import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";
import COMPONENT_TYPE_MAPPING_READ_ONLY from "./FormElementReadOnlyTypeMapping";
import {
  READ_ONLY,
} from "../../../../views/Geschaeftsregel/Module/UiComponents";
import {
  READ_ONLY as KF_READ_ONLY,
} from "../../../../views/KF/UiComponents";
import {
  READ_ONLY as SITZUNG_READ_ONLY
} from "../../../../views/Sitzung/SitzungModule/Module/UiComponents";

import UiReadOnly from "../../ui/UiReadOnly/UiReadOnly.vue";
import UiInputReadOnly from "../../ui/UiInput/UiInputReadOnly/UiInputReadOnly.vue";
import UiTextareaReadOnly from "../../ui/UiTextarea/UiTextareaReadOnly/UiTextareaReadOnly.vue";
import UiDatepickerReadOnly from "../../ui/UiDatepicker/UiDatepickerReadOnly/UiDatepickerReadOnly.vue";
import UiSelectReadOnly from "../../ui/UiSelect/UiSelectReadOnly/UiSelectReadOnly.vue";
import UiMultiselectReadOnly from "../../ui/UiSelect/UiMultiselectReadOnly/UiMultiselectReadOnly.vue";
import UiBooleanReadOnly from "../../ui/UiBoolean/UiBooleanReadOnly/UiBooleanReadOnly.vue";
import UiDocumentReadOnly from "../../ui/UiDocument/UiDocumentReadOnly/UiDocumentReadOnly.vue";
import UiCoordinatesReadOnly from "../../ui/UiCoordinates/UiCoordinatesReadOnly/UiCoordinatesReadOnly.vue";
import UiFloatReadOnly from "../../ui/UiFloat/UiFloatReadOnly/UiFloatReadOnly.vue";
import UiCurrencyReadOnly from "../../ui/UiCurrency/UiCurrencyReadOnly/UiCurrencyReadOnly.vue";
import UiTemplateReadOnly from "../../ui/UiTemplate/UiTemplateReadOnly/UiTemplateReadOnly.vue";
import UiDayMonthReadOnly from "../../ui/UiDayMonth/UiDayMonthReadOnly/UiDayMonthReadOnly.vue";
import UiIbanReadOnly from "../../ui/UiIban/UiIbanReadOnly/UiIbanReadOnly.vue";
import UiJsonReadOnly from "../../ui/UiJson/UiJsonReadOnly/UiJsonReadOnly.vue";
import UiJsonEditorReadOnly from "../../ui/UiJsonEditor/UiJsonEditorReadOnly/UiJsonEditorReadOnly.vue";
import UiDynamischeFeldwertlisteReadOnly
  from "../../ui/UiDynamischeFeldwertliste/UiDynamischeFeldwertlisteReadOnly/UiDynamischeFeldwertlisteReadOnly.vue";
import UiDynamischeFormDefinitionReadOnly
  from "../../ui/UiDynamischeFormDefinition/UiDynamischeFormDefinitionReadOnly/UiDynamischeFormDefinitionReadOnly.vue";
import UiDynamischesFormularReadOnly from "../../ui/UiDynamischesFormular/UiDynamischesFormularReadOnly/UiDynamischesFormularReadOnly.vue";
import UiKatalogExtraDataReadOnly from "../../ui/UiKatalogExtraData/UiKatalogExtraDataReadOnly/UiKatalogExtraDataReadOnly.vue";
import UiAutomaticReadOnly from "../../ui/UiAutomatic/UiAutomaticReadOnly/UiAutomaticReadOnly.vue";
import UiRandomStringPartsReadOnly from "../../ui/UiRandomStringParts/UiRandomStringPartsReadOnly/UiRandomStringPartsReadOnly.vue";
import UiRatingReadOnly from "../../ui/UiRating/UiRatingReadOnly/UiRatingReadOnly.vue";
import UiStrMappingReadOnly from "../../ui/UiStrMapping/UiStrMappingReadOnly/UiStrMappingReadOnly.vue";
import UiOneCheckboxReadOnly from "../../ui/UiOneCheckbox/UiOneCheckboxReadOnly/UiOneCheckboxReadOnly.vue";
import UiTextbausteinReadOnly from "../../ui/UiTextbaustein/UiTextbausteinReadOnly/UiTextbausteinReadOnly.vue";
import UiValidatedJsonReadOnly from "../../ui/UiValidatedJson/UiValidatedJsonReadOnly/UiValidatedJsonReadOnly.vue";
import UiTreeReadOnly from "../../ui/UiTree/UiTreeReadOnly/UiTreeReadOnly.vue";
import UiTinymceReadOnly from "../../ui/UiTinymce/UiTinymceReadOnly/UiTinymceReadOnly.vue";

// @vue/component
export default {
  name: "FormElementReadOnly",
  components: {
    PuxIcon,
    PuxTooltip,
    PuxTranslate,
    UiReadOnly,
    UiInputReadOnly,
    UiDatepickerReadOnly,
    UiSelectReadOnly,
    UiMultiselectReadOnly,
    UiBooleanReadOnly,
    UiTextareaReadOnly,
    UiDocumentReadOnly,
    UiCoordinatesReadOnly,
    UiFloatReadOnly,
    UiCurrencyReadOnly,
    UiTemplateReadOnly,
    UiDayMonthReadOnly,
    UiIbanReadOnly,
    UiJsonReadOnly,
    UiJsonEditorReadOnly,
    UiAutomaticReadOnly,
    UiRandomStringPartsReadOnly,
    UiRatingReadOnly,
    UiStrMappingReadOnly,
    UiOneCheckboxReadOnly,
    UiTextbausteinReadOnly,
    UiDynamischeFeldwertlisteReadOnly,
    UiDynamischeFormDefinitionReadOnly,
    UiDynamischesFormularReadOnly,
    UiKatalogExtraDataReadOnly,
    UiValidatedJsonReadOnly,
    UiTreeReadOnly,
    UiTinymceReadOnly,
    ...READ_ONLY,
    ...KF_READ_ONLY,
    ...SITZUNG_READ_ONLY,
  },
  props: {
    modelValue: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    formModel: {
      type: Object,
      required: false,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    idPrefix: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Array,
      required: false,
      default: undefined,
    },
    collection: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    dependencyValue: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
      required: false,
    },
    extraStatic: {
      type: Object,
      required: false,
      default: undefined,
      info: "nur für children-Komponenten, pro Komponente unterschiedlich z.B. { notLoadList: true } für ui-select",
    },
    tag: {
      type: String,
      required: false,
      default: "div",
    },
  },
  data() {
    return {
      componentTypeMappingReadOnly: COMPONENT_TYPE_MAPPING_READ_ONLY,
    };
  },
  computed: {
    htmlId() {
      if (this.options.htmlId) {
        return this.options.htmlId;
      }
      return `${ this.idPrefix }${ this.options.attrId || this.options.key || this.options.id }`;
    },

    currentComponent() {
      return this.componentTypeMappingReadOnly[this.options.type || "text"];
    },
  },
};
