import AButton from "aloha-vue/src/AButton/AButton";

import translate from "../../../../directives/translate";

import UIReadOnlyMixin from "../../UIReadOnlyMixin";

import {
  toFormElementFromParameter,
  initModelFromList,
} from "../../../../functions/mappingForParameterToFormElement";

import {
  cloneDeep,
  defaults,
  forEach,
  isArray,
  isNil,
  orderBy,
  size,
} from "lodash-es";

// @vue/component
export default {
  name: "UiDynamischeFormDefinitionReadOnly",
  components: {
    AButton,
  },
  directives: {
    translate,
  },
  mixins: [
    UIReadOnlyMixin,
  ],
  data() {
    return {
      showDetails: {},
    };
  },
  computed: {
    localIdPrefix() {
      return `readonly_${ this.idPrefix }`;
    },
    modelSize() {
      return size(this.localModel);
    },
    modelList() {
      return orderBy(this.localModel, ["pos"]);
    },
    formFields() {
      return this.options.formFields || {
        base: {},
        typed: {}
      };
    },
    baseList() {
      const FORM = cloneDeep(this.formFields.base);
      forEach(FORM, el => el.help_text = undefined);
      return toFormElementFromParameter({ obj: FORM, showLabel: true }).list;
    },
    typeList() {
      const TFORM = cloneDeep(this.formFields.typed);
      const TYPED_FORM = {};
      forEach(TFORM, (typeDef, type) => {
        forEach(typeDef, el => el.help_text = undefined);
        TYPED_FORM[type] = toFormElementFromParameter({ obj: typeDef, showLabel: true }).list;
      });
      return TYPED_FORM;
    },
    localModel() {
      const MODEL = cloneDeep(this.model);
      forEach(MODEL, v => {
        defaults(v, initModelFromList({ list: this.baseList }));
        if (!isNil(v.type)) {
          defaults(v, initModelFromList({ list: this.typeList[v.type] }));
        }
      });
      return MODEL;
    },
    translationNoEntrey() {
      return { text: "_TXT_UI_DYNAMISCHE_FORMDEFINITION_READ_ONLY_EMPTY_" };
    },
  },
  beforeCreate() {
    this.$options.components.FormElementReadOnly = require("../../../FormElement/FormElementReadOnly/FormElementReadOnly.vue").default;
  },
  methods: {
    toggleDetails({ id }) {
      const DETAILS = cloneDeep(this.showDetails);
      DETAILS[id] = !DETAILS[id];
      this.showDetails = DETAILS;
    },
    showField(value) {
      return !isNil(value) && !(isArray(value) && size(value) === 0);
    }
  }
};
