import {
  ref,
} from "vue";

import {
  isFunction,
} from "lodash-es";

export default function FeedbackAPI() {
  const hasFeedback = ref(false);

  const checkFeedback = () => {
    if (isFunction(window.usabilla_live)) {
      hasFeedback.value = true;
    }
  };

  const startFeedback = () => {
    try {
      window.usabilla_live("click");
    } catch (e) {

    }
  };

  return {
    checkFeedback,
    hasFeedback,
    startFeedback,
  };
}
