import PuxTranslate from "../../../PuxTranslate/PuxTranslate.vue";
import UiValidatedJsonReadOnly from "../../../../../global/components/ui/UiValidatedJson/UiValidatedJsonReadOnly/UiValidatedJsonReadOnly.vue";

import JsonOptionsMixin from "../JsonOptionsMixin";
import UIReadOnlyMixin from "../../UIReadOnlyMixin";

// @vue/component
export default {
  name: "UiDynamischesFormularReadOnly",
  components: {
    PuxTranslate,
    UiValidatedJsonReadOnly,
  },
  mixins: [
    JsonOptionsMixin,
    UIReadOnlyMixin,
  ],
};
