import {
  MAPPING_READONLY,
} from "../../../../views/Geschaeftsregel/Module/UiComponents";
import {
  MAPPING_READONLY as KF_MAPPING_READONLY,
} from "../../../../views/KF/UiComponents";
import {
  MAPPING_READONLY as SITZUNG_MAPPING_READONLY
} from "../../../../views/Sitzung/SitzungModule/Module/UiComponents";

export default {
  text: "ui-input-read-only",
  email: "ui-input-read-only",
  password: "ui-input-read-only",
  number: "ui-input-read-only",
  integer: "ui-input-read-only",
  decimal: "ui-input-read-only",
  color: "ui-input-read-only",
  math_expression: "ui-automatic-read-only",
  decimal_signal: "ui-automatic-read-only",
  decimal_objektkatalog_data: "ui-input-read-only",
  text_objektkatalog_data: "ui-input-read-only",
  decimal_katalog_data: "ui-input-read-only",
  text_katalog_data: "ui-input-read-only",
  katalog_extra_data: "ui-katalog-extra-data-read-only",
  regex_config: "ui-input-read-only",
  textarea: "ui-textarea-read-only",
  select: "ui-select-read-only",
  single_choice: "ui-select-read-only",
  single_choice_objektkatalog: "ui-select-read-only",
  gutscheincode: "ui-input-read-only",
  gutscheinwert: "ui-automatic-read-only",
  multiselect: "ui-multiselect-read-only",
  multi_select: "ui-multiselect-read-only",
  selectordered: "ui-multiselect-read-only",
  date: "ui-datepicker-read-only",
  date_expression: "ui-automatic-read-only",
  datetime: "ui-datepicker-read-only",
  time: "ui-datepicker-read-only",
  daterange: "ui-datepicker-range-read-only",
  richtext: "ui-tinymce-read-only",
  textbaustein: "ui-textbaustein-read-only",
  checkbox: "ui-checkbox-read-only",
  radio: "ui-radiobutton-read-only",
  boolean: "ui-boolean-read-only",
  document: "ui-document-read-only",
  coordinates: "ui-coordinates-read-only",
  float: "ui-float-read-only",
  currency: "ui-currency-read-only",
  daymonth: "ui-day-month-read-only",
  iban: "ui-iban-read-only",
  json: "ui-json-read-only",
  json_editor: "ui-json-editor-read-only",
  template: "ui-template-read-only",
  rating: "ui-rating-read-only",
  validated_rating: "ui-rating-read-only",
  str_mapping: "ui-str-mapping-read-only",
  dynamische_feldwertliste: "ui-dynamische-feldwertliste-read-only",
  form: "ui-dynamisches-formular-read-only",
  form_definition: "ui-dynamische-form-definition-read-only",
  dynamische_mehrfachauswahl_anzeigen: "ui-select-read-only",
  validated_json: "ui-validated-json-read-only",
  one_checkbox: "ui-one-checkbox-read-only",
  tree: "ui-tree-read-only",
  user: "ui-read-only",
  random_string_parts: "ui-random-string-parts-read-only",
  confirm: "ui-boolean-read-only",
  ...MAPPING_READONLY,
  ...KF_MAPPING_READONLY,
  ...SITZUNG_MAPPING_READONLY,
};
