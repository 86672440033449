import ALink from "aloha-vue/src/ALink/ALink";
import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";

import ExtraAPI from "./compositionAPI/ExtraAPI";
import LinkAPI from "./compositionAPI/LinkAPI";
import TitleAPI from "./compositionAPI/TitleAPI";
import ValueAPI from "./compositionAPI/ValueAPI";

// @vue/component
export default {
  name: "PuxTableRowDefaultSlot",
  components: {
    ALink,
    PuxTranslate,
  },
  props: {
    column: {
      type: Object,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      title,
    } = TitleAPI(props);

    const {
      extra,
    } = ExtraAPI(props);

    const {
      valueAsString,
    } = ValueAPI(props);

    const {
      href,
      showLink,
      target,
      toLocal,
    } = LinkAPI(props);

    return {
      extra,
      href,
      showLink,
      target,
      title,
      toLocal,
      valueAsString,
    };
  },
};
