import AButton from "aloha-vue/src/AButton/AButton";
import PuxTranslate from "../../../PuxTranslate/PuxTranslate.vue";

import translate from "../../../../directives/translate";

import UIComponentMixin from "../../UIComponentMixin";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";

import {
  toFormElementFromParameter
} from "../../../../functions/mappingForParameterToFormElement";

import {
  find,
  get
} from "lodash-es";

// @vue/component
export default {
  name: "UiKatalogExtraDataReadOnly",
  components: {
    AButton,
    PuxTranslate,
  },
  directives: {
    translate,
  },
  mixins: [
    UIComponentMixin,
  ],
  inheritAttrs: false,
  setup() {
    const {
      getHttp,
    } = AHttpAPI();

    return {
      getHttp,
    };
  },
  data() {
    return {
      katalog: undefined,
    };
  },
  computed: {
    translateOptionsPlaceholder() {
      if (this.options.placeholder) {
        return {
          placeholder: this.options.placeholder,
        };
      }
      return {};
    },

    formElementOptions() {
      const OPTIONS = get(this.katalog, ["kat_extra_definition", this.options.katalog_extra_field_id]);
      if (!OPTIONS) {
        return;
      }
      return toFormElementFromParameter({
        obj: { [OPTIONS.id]: OPTIONS },
        showLabel: false,
        staticOptions: {
          helpText: null
        }
      }).list[0];
    },

    relatedKatalogSelected() {
      return !this.options.katalog_spalte_id || (
        this.formModel && !!this.formModel[this.options.katalog_spalte_id]
      );
    }
  },
  beforeCreate() {
    this.$options.components.FormElementReadOnly = require("../../../../components/FormElement/FormElementReadOnly/FormElementReadOnly.vue").default;
  },
  created() {
    this.loadKatalog();
  },
  methods: {
    loadKatalog() {
      this.getHttp({
        url: "katalog/",
        urlParams: {
          key: "dynkatalog"
        },
        apiSaveId: "dynkatalog",
      }).then(
        response => {
          this.katalog = find(response, el => el.id === this.options.katalog);
        }
      );
    }
  },
};
