import PuxTranslate from "../../PuxTranslate/PuxTranslate.vue";
import UiValidatedJson from "../../../../global/components/ui/UiValidatedJson/UiValidatedJson.vue";

import JsonOptionsMixin from "./JsonOptionsMixin";
import UIComponentMixin from "../UIComponentMixin";

// @vue/component
export default {
  name: "UiDynamischesFormular",
  components: {
    PuxTranslate,
    UiValidatedJson,
  },
  mixins: [
    JsonOptionsMixin,
    UIComponentMixin,
  ],
};
