import {
  translation,
  timeTranslationLastChanged,
} from "aloha-vue/src/ATranslation/compositionAPI/ATranslationAPI";

import IsFilter from "../filters/IsFilter";
import moment from "moment/moment";
import {
  deburr,
  toString,
  trim,
  toLower,
  get,
  isArray,
  isPlainObject,
  isUndefined,
  forEach,
  isFunction,
  isString,
  startsWith,
  isObject,
  assign,
  cloneDeep,
  isNumber,
  round,
} from "lodash-es";

export function changeTextToId(text) {
  return deburr(toLower(trim(toString(text))).replace(/ /g, "_"));
}

export function gettext(text) {
  if (!timeTranslationLastChanged.value) {
    return "";
  }
  if (translation && !isUndefined(translation[text])) {
    return translation[text];
  }
  return text;
}

export function isTranslatePlaceholder(text) {
  return !(!isString(text) ||
    text[0] !== "_" ||
    text[text.length - 1] !== "_");
}

export function getTextFromPlaceholder(text) {
  if (!isTranslatePlaceholder(text)) {
    return text;
  }
  return gettext(text);
}

export function getTranslatedText(text) {
  const TEXT_TRANSLATED = gettext(text);
  if (TEXT_TRANSLATED !== text) {
    return TEXT_TRANSLATED;
  }
  return "";
}

export function replaceText({ text = "", object }) {
  if (!isPlainObject(object)) {
    return text;
  }
  let textClone = text;
  let searchIndexStart = 0;
  while (true) {
    const firstIndex = textClone.indexOf("{{", searchIndexStart);
    const lastIndex = textClone.indexOf("}}", searchIndexStart);

    if (firstIndex === -1 || lastIndex === -1) {
      break;
    }

    const {
      id,
      FILTERS,
    } = setIdAndFilter({ text: textClone.slice(firstIndex + 2, lastIndex).trim() });


    let valueFromObject = get(object, id);
    if (FILTERS.length) {
      forEach(FILTERS, filter => {
        valueFromObject = IsFilter(valueFromObject, filter.filterName, filter.filterParameter);
      });
    }

    textClone = spliceString({
      text: textClone,
      replaceText: valueFromObject,
      firstIndex,
      lastindex: lastIndex + 2,
    });
    searchIndexStart = firstIndex + `${ valueFromObject }`.length;
  }
  return textClone;
}

function setIdAndFilter({ text }) {
  let id = text;
  const FILTERS = [];
  const FILTER_INDEX_IN_TEXT = text.indexOf("|");
  if (FILTER_INDEX_IN_TEXT !== -1) {
    id = text.slice(0, FILTER_INDEX_IN_TEXT).trim();
    let filtersText = text.slice(FILTER_INDEX_IN_TEXT + 1).trim();
    while (true) {
      const FILTER_INDEX = filtersText.indexOf("|");
      if (FILTER_INDEX === -1) {
        FILTERS.push(setFilterParameter({ text: filtersText }));
        break;
      } else {
        FILTERS.push(setFilterParameter({ text: filtersText.slice(0, FILTER_INDEX) }));
        filtersText = filtersText.slice(FILTER_INDEX + 1);
      }
    }
  }

  return {
    id,
    FILTERS,
  };
}

export function setFilterParameter({ text }) {
  const FILTER_TEXT_LOCAL = text.trim();
  let filterName = FILTER_TEXT_LOCAL;
  let filterParameter = undefined;
  const FIRST_INDEX = FILTER_TEXT_LOCAL.indexOf("(");
  const LAST_INDEX = FILTER_TEXT_LOCAL.indexOf(")");
  if (FIRST_INDEX === -1 || LAST_INDEX === -1) {
    return {
      filterName,
      filterParameter,
    };
  }

  filterName = FILTER_TEXT_LOCAL.slice(0, FIRST_INDEX);
  const PARAM_STR = FILTER_TEXT_LOCAL.slice(FIRST_INDEX + 1, LAST_INDEX).trim();
  try {
    // eslint-disable-next-line no-eval
    filterParameter = eval(`(${ PARAM_STR })`);
  } catch (e) {

  }
  return {
    filterName,
    filterParameter,
  };
}

function spliceString({
  text = "",
  replaceText = "",
  firstIndex,
  lastindex,
}) {
  return `${ text.slice(0, firstIndex) }${ replaceText }${ text.slice(lastindex, text.length) }`;
}

export function checkScrollbarWith() {
  const DIV = document.createElement("div");
  DIV.style.overflowY = "scroll";
  DIV.style.width = "30px";
  DIV.style.height = "30px";

  document.body.append(DIV);
  const SCROLL_WIDTH = DIV.offsetWidth - DIV.clientWidth;

  DIV.remove();

  return SCROLL_WIDTH;
}

export function scrollParentToChild({ parent, child, checkViewable }) {
  // Where is the parent on page
  const PARENT_RECT = parent.getBoundingClientRect();

  // Where is the child
  const CHILD_RECT = child.getBoundingClientRect();
  if (!checkViewable) {
    parent.scrollTop = (CHILD_RECT.top + parent.scrollTop) - PARENT_RECT.top;
    return;
  }

  // What can you see?
  const PARENT_VIEWABLE_AREA = {
    height: parent.clientHeight,
    width: parent.clientWidth
  };
  // Is the child viewable?
  const IS_VIEWABLE = (CHILD_RECT.top >= PARENT_RECT.top) && (CHILD_RECT.top <= PARENT_RECT.top + PARENT_VIEWABLE_AREA.height);

  // if you can't see the child try to scroll parent
  if (!IS_VIEWABLE) {
    // scroll by offset relative to parent
    parent.scrollTop = (CHILD_RECT.top + parent.scrollTop) - PARENT_RECT.top;
  }
}

export function selectElementContent({ element }) {
  if (document.selection) {
    const range = document.body.createTextRange();
    range.moveToElementText(element);
    range.select();
  } else if (isFunction(window.getSelection)) {
    const range = document.createRange();
    range.selectNodeContents(element);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
}

export function copyToClipboard({ text }) {
  const TMP_EL = document.createElement("div");
  TMP_EL.style.position = "absolute";
  TMP_EL.style.left = "-9999px";
  document.body.appendChild(TMP_EL);
  TMP_EL.innerHTML = text;
  selectElementContent({ element: TMP_EL });
  document.execCommand("copy");
  document.body.removeChild(TMP_EL);
}

export function getCookie(name) {
  const r = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  if (r) {
    return r[2].replace("%40", "@");
  }
  return "";
}

export function deleteCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function getIdForTableRowAction({ tableId, index }) {
  return `${ tableId }_action_${ index }`;
}

export function getIdForTableRowDropdown({ tableId, index }) {
  return `${ tableId }_action_dropdown_${ index }`;
}

export function getSelectorCloseForTableRowFunction({ tableId, index }) {
  return [
    `#${ getIdForTableRowDropdown({ tableId, index }) }`,
    `#${ getIdForTableRowAction({ tableId, index }) }`,
    `#${ tableId }`,
  ];
}

export function getInternalOrExternalPathToFile(path) {
  if (!path) {
    return path;
  }
  if (startsWith(path, "http:") || startsWith(path, "https:") || startsWith(path, "file:")) {
    return path;
  }
  return `/api/assets/${ path }`;
}

export function getDifferenceObjects(obj1 = {}, obj2 = {}) {
  let diff = {};
  forEach(obj1, (item, key) => {
    diff = assign(diff, _getDifferenceObjects(item, obj2, key));
  });
  forEach(obj2, (item, key) => {
    diff = assign(diff, _getDifferenceObjects(item, obj1, key));
  });
  return diff;
}

function _getDifferenceObjects(item, obj, path) {
  let diff = {};
  if (isObject(item)) {
    forEach(item, (i, key) => {
      const PATH = `${ path }.${ key }`;
      diff = assign(diff, _getDifferenceObjects(i, obj, PATH));
    });
  } else {
    const VALUE_FROM_OBJ = get(obj, path);
    if (item !== VALUE_FROM_OBJ) {
      diff[path] = true;
    }
  }
  return diff;
}

export function setNumberToStringInPythonFormatJson(object) {
  const OBJECT = cloneDeep(object);
  forEach(OBJECT, (item, key) => {
    if (isObject(item)) {
      OBJECT[key] = setNumberToStringInPythonFormatJson(object);
    } else {
      OBJECT[key] = setNumberToStringInPythonFormat(item);
    }
  });
  return OBJECT;
}

export function setNumberToStringInPythonFormat(value) {
  if (isNumber(value)) {
    return `${ round(value, 2).toFixed(2) }`;
  }
  return value;
}

export function isArrayInArray(array1, array2) {
  let isArrayInArray = true;
  forEach(array1, item => {
    if (array2.indexOf(item) === -1) {
      isArrayInArray = false;
      return false;
    }
  });
  return isArrayInArray;
}

export function getAllStringsFromJson(json) {
  const STRINGS = [];
  forEach(json, value => {
    if (isObject(value)) {
      STRINGS.push(...getAllStringsFromJson(value));
    } else if (isString(value)) {
      STRINGS.push(value);
    }
  });
  return STRINGS;
}

export function isAnythingButValueInArray(array = [], value = "") {
  let isNotOnlyValue = false;
  if (isArray(value)) {
    forEach(array, item => {
      if (value.indexOf(item) === -1) {
        isNotOnlyValue = true;
        return false;
      }
    });
  } else {
    forEach(array, item => {
      if (item !== value) {
        isNotOnlyValue = true;
        return false;
      }
    });
  }
  return isNotOnlyValue;
}

export function isArrayOfStrings(array) {
  if (!isArray(array)) {
    return false;
  }

  return array.every(item => isString(item));
}

function calculateTimeDifference({ from, to }) {
  if (!from || !to) {
    return {};
  }
  const FROM = moment(from);
  const TO = moment(to).add(1, "days");
  const MONTHS = TO.diff(FROM, "months");
  FROM.add(MONTHS, "months");
  const DAYS = TO.diff(FROM, "days");
  return {
    months: MONTHS,
    days: DAYS,
  };
}

export function getTimeDifference({ from, to }) {
  const TIME_DIFFERENCE_CALCULATED = calculateTimeDifference({ from, to });
  let text = "";
  if (TIME_DIFFERENCE_CALCULATED.months) {
    const TEXT_MONTH = TIME_DIFFERENCE_CALCULATED.months === 1 ?
      "_TXT_MONTH_{{months}}_" :
      "_TXT_MONTHS_{{months}}_";
    text += replaceText({
      text: gettext(TEXT_MONTH),
      object: TIME_DIFFERENCE_CALCULATED,
    });
  }
  if (TIME_DIFFERENCE_CALCULATED.days) {
    if (text) {
      text += " ";
    }
    const TEXT_DAYS = TIME_DIFFERENCE_CALCULATED.days === 1 ?
      "_TXT_DAY_{{days}}_" :
      "_TXT_DAYS_{{days}}_";
    text += replaceText({
      text: gettext(TEXT_DAYS),
      object: TIME_DIFFERENCE_CALCULATED,
    });
  }

  return text;
}
