import {
  onMounted,
} from "vue";

import AElement from "aloha-vue/src/AElement/AElement";

import ChatBotAPI from "./compositionAPI/ChatBotAPI";
import FeedbackAPI from "./compositionAPI/FeedbackAPI";

// @vue/component
export default {
  name: "TheAsideButtons",
  components: {
    AElement,
  },
  setup() {
    const {
      hasPermissionChatbotView,
      initChatBotComponent,
      loadingChatbot,
    } = ChatBotAPI();

    const {
      checkFeedback,
      hasFeedback,
      startFeedback,
    } = FeedbackAPI();

    onMounted(() => {
      initChatBotComponent();
      checkFeedback();
    });

    return {
      hasFeedback,
      hasPermissionChatbotView,
      loadingChatbot,
      startFeedback,
    };
  },
};
