import HttpMixin from "../../../mixins/HttpMixin";

import { toFormElementFromParameter } from "../../../functions/mappingForParameterToFormElement";

import {
  find,
  get,
  isNil,
  size
} from "lodash-es";

export default {
  mixins: [
    HttpMixin,
  ],
  data() {
    return {
      dependencyData: [],
    };
  },
  computed: {
    validatedJsonOptions() {
      return {
        jsonParam: {
          bez: this.options.bez,
          default: null,
          help_text: this.options.help_text,
          readonly: false,
          required: true,
          typ: "validated_json",
          many: false,
          as_json: true,
          unique_fields: [],
          key_field: null,
          pos_field: null,
          min_length: null,
          max_length: null,
          deactivate_preview: false,
          hide_pos: false,
          fields: this.dynForm,
          prio: 0,
        }
      };
    },
    dependencySelected() {
      return get(this.formModel, this.options.dependency);
    },
    dynForm() {
      const dependency = this.options.dependency;
      const dependencyFormDefKey = this.options.dependencyFormDefKey;
      if (isNil(this.formModel) || isNil(this.dependencyData)
          || isNil(dependency) || isNil(dependencyFormDefKey)) {
        return this.options.formDef || {};
      }
      if (!this.hasDependencySelected) {
        return {};
      }
      const dependencyEl = find(this.dependencyData, el => el.pk === this.dependencySelected);
      return get(dependencyEl, dependencyFormDefKey, {});
    },
    hasDependencySelected() {
      return !isNil(this.dependencySelected);
    },
    hasDependencyForm() {
      return size(this.dynForm) > 0;
    },
    jsonOptions() {
      if (!this.hasDependencyForm) {
        return;
      }
      return toFormElementFromParameter({
        obj: this.validatedJsonOptions,
        showLabel: false,
      }).list[0];
    },
    noSelectionMsg() {
      return this.options.dependencyMsgNoSelection || "_MSG_UI_FORM_DEPENDECY_NO_SELECTION_";
    },
    noFormMsg() {
      return this.options.dependencyMsgNoForm || "_MSG_UI_FORM_DEPENDECY_NO_FORM_";
    },
  },
  created() {
    this.loadKatalogData();
  },
  methods: {
    loadKatalogData() {
      if (!isNil(this.dependencyKatalog)) {
        return Promise.resolve([]);
      }
      return this.getHttp({
        url: "katalog/",
        urlParams: {
          key: this.options.dependencyKatalog
        },
        apiSaveId: this.options.dependencyKatalog
      }).then(
        response => this.dependencyData = response
      );
    }
  }
};
