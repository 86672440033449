import {
  ref,
} from "vue";

import ANotificationAPI from "aloha-vue/src/compositionAPI/ANotificationAPI";
import PermissionGlobalAPI from "../../../global/compositionAPI/PermissionGlobalAPI";

import Cookies from "js-cookie";

export default function ChatBotAPI() {
  const COOKIE_NAME_CHATBOT_CLOSE = "proandi_chatbot_close";
  const loadingChatbot = ref(true);
  const ONE_MONTH = 30;

  const {
    addNotification,
  } = ANotificationAPI();

  const {
    hasPermission: hasPermissionChatbotView,
  } = PermissionGlobalAPI({
    permission: "chatbot.view",
  });

  const onToggleChatbot = ({ isOpen }) => {
    Cookies.set(COOKIE_NAME_CHATBOT_CLOSE, !isOpen, { expires: ONE_MONTH });
  };

  const initChatBotComponent = () => {
    if (!hasPermissionChatbotView.value) {
      return;
    }

    const cookieChatbotClose = Cookies.get(COOKIE_NAME_CHATBOT_CLOSE);
    const isChatbotOpen = cookieChatbotClose !== "true";

    const script = document.createElement("script");
    script.src = `/chatbot/chat-bot.iife.js?${ new Date().getTime() }`;
    script.async = true;

    script.onload = () => {
      if (!window._ChatBot) {
        addNotification({
          text: "_MSG_CHATBOT_NOT_INITIALED_",
          type: "error",
        });

        return;
      }
      loadingChatbot.value = false;
      setTimeout(() => {
        const chatBotInstance = new window._ChatBot();

        chatBotInstance.init({
          selector: "#chat_bot_wrapper",
          props: {
            btnChatClass: "a_btn a_btn_primary btn_chatbot",
            initialOpen: isChatbotOpen,
            onToggle: onToggleChatbot,
            datenschutzLinkAttributes: {
              href: "https://www.aktion-mensch.de/datenschutz",
              target: "_blank",
            },
          },
        });
      });
    };

    script.onerror = () => {
      addNotification({
        text: "_MSG_CHATBOT_NOT_LOADED_",
        type: "error",
      });
    };

    document.head.appendChild(script);
  };


  return {
    hasPermissionChatbotView,
    initChatBotComponent,
    loadingChatbot,
  };
}
