import { toFormElementFromParameter } from "../../../../global/functions/mappingForParameterToFormElement";

export default {
  data() {
    return {
      jsonParam: {
        bez: "Kontextdaten für Bezeichnungen",
        default: null,
        help_text: "Definiert aus welchen Regeln Daten gesammelt werden sollen, die dann in pos_bezeichnung und periode_bezeichnung als Variablen verwendert werden können.",
        readonly: false,
        options: {
          allow_null: true,
          required: false,
        },
        typ: "validated_json",
        many: true,
        as_json: true,
        unique_fields: [],
        key_field: "__id__",
        pos_field: null,
        min_length: null,
        max_length: null,
        element_label: "Element",
        element_template: "{{__id__}}",
        element_errors: true,
        deactivate_ordering: false,
        deactivate_preview: false,
        hide_pos: false,
        fields: {
          regel: {
            prio: 0,
            bez: null,
            default: null,
            help_text: null,
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              displaymode: "select2",
            },
            typ: "single_choice",
            katalog: "foerderregel",
          },
          feld: {
            prio: 1,
            bez: null,
            default: null,
            help_text: null,
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              max_length: null,
              min_length: null,
            },
            typ: "string",
          },
          __id__: {
            prio: -1,
            bez: "ID",
            default: null,
            help_text: "ID-Feld für das Element. Muss eindeutig sein",
            readonly: false,
            options: {
              allow_null: false,
              required: true,
              max_length: 60,
              min_length: null,
            },
            typ: "string",
          },
        },
        prio: 7,
        wert: null,
      },
    };
  },
  computed: {
    jsonOptions() {
      return toFormElementFromParameter({
        obj: { dimensionen: this.jsonParam },
        showLabel: true,
        staticOptions: {
          required: this.options.required,
          options: this.options.options,
        },
      }).list[0];
    },
  },
};
